import { useEffect, useState } from "react";

export const useApi = <T>(url: string, defaultValue: T, doRequest: boolean) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<T>(defaultValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
    if (!doRequest) {
      return;
    }
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(url, {
      signal: signal,
    })
      .then(async (response) => {
        const json = await response.json();
        if (response.status !== 200) {
          throw new Error(json?.message ?? json?.error ?? json);
        }
        setValue(json);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [url]);
  return [value, loading, error] as const;
};

export interface GameInfo {
  id: number | string;
  name: string;
  imageUrl: string;
}
const baseUrl = "https://igdb-api.k8s.bylundia.se";
//const baseUrl = "http://localhost:3000";
export const useSearch = (query: string) => {
  return useApi<GameInfo[] | undefined>(
    `${baseUrl}/search?q=${query}`,
    [],
    !!query
  );
};
