/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from "@emotion/react";

import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { GameInfo, useSearch } from "./api";
import { useDebounce, useLocalStorage } from "./hooks";

export const App: React.FC = () => {
  return (
    <>
      <h1>About You: Video Games</h1>
      <GameGrid />
    </>
  );
};

const GameGrid: React.FC = () => {
  const titles = [
    "Favorite Game of all Time",
    "Favorite Series",
    "Best Soundtrack",
    "Favorite Protagonist",
    "Favorite Villain",
    "Best Story",
    "Have not played but want to",
    "You Love Everyone Hates",
    "You Hate Everyone Loves",
    "Best Art Style",
    "Favorite Ending",
    "Favorite Boss Fight",
    "Childhood Game",
    "Relaxing Game",
    "Stressful Game",
    "Game you always come back to",
    "Guilty Pleasure",
    "Tons of Hours Played",
  ];

  return (
    <div
      css={{
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridTemplateRows: "repeat(3, 1fr)",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      {titles.map((t, i) => (
        <GameGridItem key={i} title={t}></GameGridItem>
      ))}
    </div>
  );
};

const GameGridItem: React.FC<{ title: string }> = ({ title }) => {
  const [game, setGame] = useLocalStorage<GameInfo | null>(
    "game-" + title,
    null
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button
        onClick={handleOpen}
        css={{
          background: "none",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "black",
          cursor: "pointer",
          display: "block",
          padding: 0,
          transition: "background 150ms ease",
          "&:hover": {
            background: "rgba(0,0,0,0.25)",
          },
          fontSize: "16px",
        }}
      >
        <div
          css={{
            position: "relative",
            "&::before": {
              content: '""',
              display: "inline-block",
              width: "1px",
              height: "0",
              paddingBottom: "calc(100% / 0.75 + 60px)",
            },
          }}
        >
          <div
            css={{
              position: "absolute",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {game ? (
              <img
                src={game.imageUrl}
                css={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  objectFit: "cover",
                  zIndex: -1,
                }}
              />
            ) : null}
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                height: "60px",
                justifyContent: "space-around",
              }}
            >
              {game ? <span>{game.name}</span> : null}
              <span>{title}</span>
            </div>
          </div>
        </div>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <GameSearchField
            onSelect={(game) => {
              setGame(game);
            }}
          ></GameSearchField>
        </Box>
      </Modal>
    </>
  );
};

const GameSearchField: React.FC<{
  onSelect: (game: GameInfo) => void;
}> = ({ onSelect }) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);
  const [options, loading, error] = useSearch(debouncedQuery);
  console.log({ options, loading, error });

  return (
    <>
      <Autocomplete
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        filterOptions={(options, state) => options ?? []}
        options={options ?? []}
        loading={loading}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        onChange={(event, value) => {
          if (value) onSelect(value);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Game"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error ? <p css={{ color: "red" }}>{`${error}`}</p> : null}
    </>
  );
};
